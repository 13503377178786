import React from 'react';
import './BrandSlider.css';

const BrandSlider = () => {
  const brands = [
    "Hermes", "Louis Vuitton", "Chanel", "Dior", "Tom Ford", "Burberry", 
    "Cartier", "Jo Malone", "Fendi"
  ];

  return (
    <div className="slider-container">
      <div className="slider">
        {brands.concat(brands).map((brand, index) => (
          <span key={index} className="brand-item">
            <img className='icon' src='./star.png'></img>
            <span className="brand-name">{brand}</span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default BrandSlider;
