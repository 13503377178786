import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import { toast } from "react-toastify";

export default function CreateProduct() {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    Object.keys(inputs).forEach((key) => {
      formData.append(key, inputs[key]);
    });
    if (file) {
      formData.append("image", file);
    }

    try {
      const response = await axios.post(
        "https://www.kcollection.az/kcollection/dashboard.php/save",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      navigate("/dashboard");
      toast("Yeni məhsul əlavə olundu")
    } catch (error) {
      console.error("Error while saving the product:", error);
    }
  };

  return (
    <>
      <DashboardHeader />
      <div className="max-w-containerSm md:max-w-container py-20 mx-auto">
        <form className="bg-white" onSubmit={handleSubmit}>
          <table cellSpacing="10">
            <tbody>
              <tr>
                <th>
                  <label>Ad: </label>
                </th>
                <td>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <label>Brend: </label>
                </th>
                <td>
                  <input
                    type="text"
                    name="brand"
                    onChange={handleChange}
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <label>Qiymət: </label>
                </th>
                <td>
                  <input
                    type="number"
                    name="price"
                    onChange={handleChange}
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <label>Gender: </label>
                </th>
                <td>
                  <select
                    name="gender"
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seçin</option>
                    <option value="men">Kişi</option>
                    <option value="women">Qadın</option>
                    <option value="unisex">Unisex</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>
                  <label>Ölçü: </label>
                </th>
                <td>
                  <input
                    type="number"
                    name="volume"
                    onChange={handleChange}
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <label>Şəkil: </label>
                </th>
                <td>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2" align="right">
                  <button
                    type="submit"
                    className="bg-[#DFB66F] text-black px-6 sm:px-7 md:px-8 py-2 rounded-full"
                  >
                    Yadda saxla
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </>
  );
}
