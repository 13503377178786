import React, { useEffect, useState } from "react";
import { sofa } from "../../Components/data/Data";
import Others from "./Others";
import { useDispatch } from "react-redux";
import { addToCart } from '../../Components/redux/orebiSlice';
import Modal from "./Modal"; // Import the Modal component
import { parfum1 } from "../../assets/images";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

export default function ProductDetail() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [selectedQuantity, setSelectedQuantity] = useState(1);  
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");

 

  const { id } = useParams();

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.kcollection.az/kcollection/dashboard.php`,
        { withCredentials: false }
      );
  
        const filteredProducts = response.data.data.filter(product => product.id == id);

        setItems(filteredProducts[0]);
        setIsLoading(false)
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  

  const decreaseQuantity = () => {
    if(selectedQuantity > 1) {
      let quantity2 = selectedQuantity - 1;
      setSelectedQuantity(quantity2);
    } 
  };

  const increaseQuantity = () => {
    let quantity2 = selectedQuantity + 1;
    setSelectedQuantity(quantity2);
  };

    const handleSubmitOrder = async () => {
  

      let inputs = {name:  clientName, email: clientEmail, phone: clientPhone, address: clientAddress, product: list.name}
      try {
        // Seçilen ürünleri post isteği ile gönder
        axios.post("https://www.kcollection.az/kcollection/orders.php/", inputs)
        .then(function (response) { 
          navigate("/");  
          setclientName('')
          setclientEmail('')
          setclientPhone('')
          setclientAddress('')
  
          // console.log(inputs);
        });
        // Başarılı olduğunda yapılacak işlemler
        toast.success("Sifarişiniz qeydə alındı");
        setIsModalOpen(false);
      } catch (error) {
        // Hata durumunda yapılacak işlemler
        console.error("Sipariş gönderilirken bir hata oluştu:", error);
      }
    };

    const genderText = list.gender === 'men' 
    ? 'Kişi' 
    : list.gender === 'women' 
    ? 'Qadın' 
    : list.gender === 'unisex' 
    ? 'Unisex' 
    : '';

  return (
   <>
   {console.log(list)}
    <div className="max-w-containerSm md:max-w-container mx-auto text-[white]">
      <p className="md:pt-10 pt-4 md:pb-7 pb-5 text-[#969696]"><a href="/">Ana səhifə</a> &gt; <a href="/products">Məhsullar</a> &gt; <span className="text-[white] font-medium">{list.name}</span></p>
      <div className="md:flex justify-between pb-[36px] md:pb-[122px]">
        <div className="md:w-[247px]">
        <div className="mb-4">
            <div className='bg-[white] rounded-xl h-[167px] mb-3 flex justify-center items-center'>
            <img className='mx-auto w-[147px] h-[147px]' src={`https://www.kcollection.az/kcollection/${list.image}`} alt='' />
          </div>
          </div>
        </div>
        <div className="md:w-[980px]">
          <h4 className="text-[22px] md:text-[28px] mb-2">{list.name}</h4>
          <div className="flex gap-2 mb-4"> 
            <div className="px-[20px] h-[24px] bg-[#6C6C6C] rounded-[20px]">{genderText}</div>
            <div className="px-[20px] h-[24px] bg-[#6C6C6C] rounded-[20px]">{list.volume} ml</div>
          </div>
          <p className="mb-4 text-[24px] md:text-[30px] font-semibold text-[#DFB66F]">{list.price} AZN</p>
          <h4 className="text-[16px] md:text-[18px] font-medium mb-4">Məhsul sayı</h4>
          <div className="w-[86px] h-[31px] flex bg-[#3F3F3F] justify-around items-center rounded-[20px]">
          <button className="h-[20px] w-[20px] rounded-[50px] flex justify-center items-center bg-[white] text-[black]" onClick={decreaseQuantity}>-</button>
          <p className="p-2">{selectedQuantity}</p>
            <button className="h-[20px] w-[20px] rounded-[50px] flex justify-center items-center bg-[black] text-[white]" onClick={increaseQuantity}>+</button>
          </div>

          <div className="flex mt-6">
            <button 
              onClick={() => setIsModalOpen(true)} // Open modal on button click
              className="mt-4 px-4 py-2 bg-[#DFB66F] text-black rounded-[16px] w-[170px]"
            >
              İndi al
            </button>
            <button 
              onClick={() =>
                dispatch(
                  addToCart({
                    id: list.id,
                    name: list.name,
                    quantity: selectedQuantity,
                    image: list.image,
                    price: list.price,
                    volume: list.volume,
                    gender: list.gender
                  })
                )
              } 
              className="mt-4 px-4 py-2 border-[#DFB66F] rounded-[16px] border-2 w-[170px] ml-4"
            >
              Səbətə at
            </button>
          </div>
        </div>
      </div>
    </div>
    {/* Modal */}
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="flex justify-between items-center mb-4  ">
            <h2 className="text-lg font-medium text-[white] ">Sizin məlumatlarınız</h2>
            <button onClick={() => setIsModalOpen(false)} className="text-[white]">
              &#10005;
            </button>
          </div>
          <form onSubmit={handleSubmitOrder}>
            <div className="mb-4">
              <label className="block text-[white]">Ad, soyad*</label>
              <input type="text" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#3F3F3F] text-white" 
               placeholder="Adınızı daxil edin"
               onChange={(event) => setclientName(event.target.value)}
               value={clientName}
               required
                />
               
            </div>
            <div className="mb-4">
              <label className="block text-[white]">E-mail*</label>
              <input type="email" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#3F3F3F] text-white"  
              placeholder="Emailinizi daxil edin"
              onChange={(event) => setclientEmail(event.target.value)}
              value={clientEmail}
              required /> 
            </div>
            <div className="mb-4">
              <label className="block text-[white]">Əlaqə nömrəsi*</label>
              <input type="number" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#3F3F3F] text-white" 
              placeholder="Telefon nömrənizi daxil edin"
              onChange={(event) => setclientPhone(event.target.value)}
              value={clientPhone}
              required /> 
            </div>
            <div className="mb-4">
              <label className="block text-[white]">Ünvan*</label>
              <input type="text" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#3F3F3F] text-white"  
              placeholder="Ünvanınızı daxil edin"
              onChange={(event) => setclientAddress(event.target.value)}
              value={clientAddress}
              required /> 
            </div>
            <div className="flex justify-center">
              <button  type="submit" className="h-[40px] pl-3 bg-[#DFB66F] text-black rounded-[12px] w-full">Səbəti təsdiqlə</button>
            </div>
          </form>
      </Modal>  

    <Others />
    </>
  );
}