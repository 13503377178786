import React from 'react'
import { about, product1, product2, product3, product4 } from '../../assets/images';

const About = () => {
  return (
    <>
      <div className="max-w-containerSm md:max-w-container mx-auto pt-[16px] md:pt-[40px] pb-[40px] md:pb-[160px]">
    <p className="text-center text-[22px] md:text-[36px] font-medium text-[#DFB66F]">Haqqımızda</p>
   <div className='md:flex mt-[20px] md:mt-[40px]'>
    <div>
   <img src={about}></img>
    </div>
    <div className='flex justify-center items-center md:ml-[16px] mt-[18px] md:mt-0'>
        <p className='text-[white] md:text-[18px] md:font-normal font-bold text-[16px]'><span className='text-[#DFB66F]'>KCollection,</span> zərif və yenilikçi parfüm kolleksiyalarımızla sizi öz dünyamıza dəvət edir. 2010-cu ildən bəri, biz lüks və mükəmməl qoxular yaratmağa özümüzü həsr etmişik. Hər bir ətirimiz, unikal tərkib hissələri və yüksək keyfiyyətli komponentlərlə hazırlanır, məqsədimiz isə müştərilərimizə həm estetik, həm də emosional bir təcrübə təqdim etməkdir. KCollection, zərif və yenilikçi parfüm kolleksiyalarımızla sizi öz dünyamıza dəvət edir. 2010-cu ildən bəri, biz lüks və mükəmməl qoxular yaratmağa özümüzü həsr etmişik.</p>
    </div>
   </div>
  </div>
    </>
  )
}

export default About
