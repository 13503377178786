import React, { useState } from 'react';
import { logo } from '../../assets/images';
import { useLocation, useNavigate } from 'react-router-dom';

const DashboardHeader = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const navigate = useNavigate();

  const location = useLocation(); // Get current route

  // Function to check if the current path is active
  const isActiveLink = (path) => location.pathname === path;

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }

  return (
    <>
        <div className='max-w-containerSm md:max-w-container mx-auto h-[86px] py-[10px] flex justify-between'>
        <button className="md:hidden focus:outline-none" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[24px] text-[white]"></i>
        {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0  bg-[black] left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>
                 <div className="flex">

          <button className="absolute top-5 right-5 p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[32px] text-[white]"></i>
          </button>
          
                 </div>
          <div className="flex flex-col items-start p-8 w-full ">
            <div className="items-center justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen">
            <div className="gap-4 max-w-containerSm flex flex-col"> 
       <a href='/'> <img src={logo} alt="Logo" className="w-[120px]" /></a>
       <a className={`text-base text-left font-normal ${isActiveLink('/') ? 'text-[#dfb66f] ' : 'text-[white]'}`} href="/"> Ana səhifə</a>
              <a href="/products" className={`text-base text-left font-normal ${isActiveLink('/products') ? 'text-[#dfb66f]' : 'text-[white]'}`}>Məhsullar</a>
              <a href="/about" className={`text-base text-left font-normal ${isActiveLink('/about') ? 'text-[#dfb66f]' : 'text-[white]'}`}>Haqqımızda</a>
              <a href="/contact" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#dfb66f]' : 'text-[white]'}`}>Əlaqə</a>

    </div>

          </div>
          </div>
        </div>
      )} 
      </button>

          <div className='md:flex items-center w-[500px] hidden '>
          <nav>
  <ul className="flex text-lg justify-between text-[white]">
    <a className='mr-[50px]' href="/dashboard">
      <li>Məhsullar</li>
    </a>
    <a className='mr-[50px]' href="/dashboard/orders">
      <li>Sifarişlər</li>
    </a>
    <a className='mr-[50px]' href="/dashboard/contact">
      <li>Əlaqə</li>
    </a>
  </ul>
</nav>

          </div>
          <div className="d-flex">
          <button  
            type="submit"
            onClick={logoutSubmit} className=" bg-[#DFB66F] text-black px-6 sm:px-7 md:px-8 py-2 rounded-full">Çıxış</button>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;




























