import React from 'react'
import { about, product1, product2, product3, product4 } from '../../../assets/images';


const About = () => {
  return (
      <div className="md:max-w-container max-w-containerSm mx-auto pt-[36px] pb-[36px] md:pb-[150px] md:pt-[60px] ">
    <p className="text-center text-[22px] md:text-[36px] font-medium mb-6 text-[#DFB66F]">Haqqımızda</p>
   <div className='md:flex justify-between'>
    <div>
   <img className='md:w-[400px] w-full' src={about}></img>
    </div>
    <div className='flex justify-center items-center md:ml-[68px] md:w-[732px] mt-[18px] md:mt-0'>
        <p className='text-[white] text-[22px] '><span className='text-[#DFB66F] font-bold'>KCollection,</span> zərif və yenilikçi parfüm kolleksiyalarımızla sizi öz dünyamıza dəvət edir. 2010-cu ildən bəri, biz lüks və mükəmməl qoxular yaratmağa özümüzü həsr etmişik. Hər bir ətirimiz, unikal tərkib hissələri və yüksək keyfiyyətli komponentlərlə hazırlanır, məqsədimiz isə müştərilərimizə həm estetik, həm də emosional bir təcrübə təqdim etməkdir.</p>
    </div>
   </div>
  </div>
  )
}

export default About