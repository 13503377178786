import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    createRoutesFromElements,
    Route,
    ScrollRestoration,
  } from "react-router-dom";
  import Header from "./Components/Header/Header";
  import Footer from "./Components/Footer/Footer";
  import Home from "../src/Pages/Home/Home";
  
  import { ToastContainer } from "react-toastify";
  import About from "./Pages/About/About";
  import Contact from "./Pages/Contact/Contact";
  import Basket from "./Pages/Basket/Basket";
  import Products from "./Pages/Products/Products";
  import Sofa from "./Pages/ProductDetail/ProductDetail";
  import ProductDetail from "./Pages/ProductDetail/ProductDetail";
  import Protected from "./Pages/Protected/Protected";
  import Dashboard from "./Pages/Dashboard/Dashboard";
  import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import CreateUser from "./Pages/CreateUser/CreateUser";
import DashboardContact from "./Pages/DashboardContact/DashboardContact";
import DashboardOffer from "./Pages/DashboardOffer/DashboardOffer"
import DashboardOrders from "./Pages/DashboardOrders/DashboardOrders";
import EditUser from "./Pages/EditUser/EditUser";
  
  
  const Layout = () => {
    return (
      <div>
         <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> 
        <Header />
        <ScrollRestoration />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Layout />}>
          {/* ==================== Header Navlink Start here =================== */}
          <Route path="/" element={ <Home />}></Route>
          <Route path="/about" element={ <About/>}></Route>
          <Route path="/products" element={ <Products/>}></Route>
          <Route path="/contact" element={ <Contact/>}></Route>
          <Route path="/basket" element={ <Basket/>}></Route>
          <Route path="/product-detail/:id" element={ <ProductDetail/>}></Route>
          <Route path="/admin" element={ <AdminPanel />}></Route>
          <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
          <Route path="/dashboard/category/:brand" element={<Protected Component={DashboardOffer} />}></Route>
        <Route path="/dashboard/create" element={<Protected Component={CreateUser} />}></Route>
            <Route path="/dashboard/orders" element={<Protected Component={DashboardOrders} />} />
            <Route path="/dashboard/contact" element={<Protected Component={DashboardContact} />} />
            <Route path="/dashboard/:id/edit" element={<Protected Component={EditUser} />} />
        </Route>
      </Route>
    )
  );
   
  function App() {
  
    return (
      <div className="font-bodyFont bg-[black]">
        <RouterProvider router={router} />
      </div>
    );
  }
  
  export default App;
  
  
  