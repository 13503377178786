import React from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import {
  deleteItem,
  drecreaseQuantity,
  increaseQuantity,
} from "../../Components/redux/orebiSlice";

const ItemCard = ({ item, isSelected, onSelect }) => { 
  const dispatch = useDispatch();
  return (
   <>
    <div className="hidden w-full md:flex items-center justify-between py-2 mb-4 bg-[#3F3F3F] rounded-[20px]">
  <div className="flex items-center gap-4 ml-4 ">
  <input
  type="checkbox"
  checked={isSelected}
  onChange={onSelect}
  className="accent-[#dfb66f] mr-2 w-4 h-4" 
/>

    <div className='bg-[white] rounded-xl  flex justify-center items-center w-[65px] h-[65px] md:w-[100px] md:h-[100px] object-cover'>
          <img className='h-[50px] md:h-[76px] mx-auto' src={`https://www.kcollection.az/kcollection/${item.image}`} alt='' />
        </div>
    <div>
      <h1 className="font-titleFont text-[16px] md:text-20px text-white mb-[10px]">{item.name}</h1>
      <p className="font-titleFont text-[16px] md:text-18px text-white mb-[10px]">Ölçü: {item.volume} • Say: {item.quantity} • Gender: {item.gender}</p>
      <p className="text-white font-semibold text-[16px] md:text-18px">{item.quantity * item.price} AZN</p>
    </div>
  </div>
  <div className="flex items-center gap-6 pr-4">
    <div className=" items-center gap-4">
   <div>
   <span  onClick={() => dispatch(deleteItem({ id: item.id}))}
      className="text-[#6C6C6C] hover:text-red-500 cursor-pointer flex justify-end mb-5 "><i class="fa-solid fa-trash-can"></i></span>
   </div>
     <div className="md:w-[86px] h-[31px] flex bg-[#6C6C6C] justify-around items-center rounded-[20px] mt-10">
          <button className="h-[20px] w-[20px] rounded-[50px] flex justify-center items-center bg-[white] text-[black]"  onClick={() => dispatch(drecreaseQuantity({ id: item.id }))}>-</button>
          <p className="p-2">{item.quantity}</p>
            <button className="h-[20px] w-[20px] rounded-[50px] flex justify-center items-center bg-[black] text-[white]" onClick={() => dispatch(increaseQuantity({ id: item.id}))}>+</button>
          </div>
    </div>

  </div>
</div>
 <div className="block md:hidden w-full items-center justify-between py-2 mb-4 bg-[#3F3F3F] rounded-[20px] ">
 <div className="flex items-center gap-4 ml-4">
 <input
  type="checkbox"
  checked={isSelected}
  onChange={onSelect}
  className="accent-[#dfb66f] mr-2 w-4 h-4" 
  
/>

   <div className='bg-[white] rounded-xl  flex justify-center items-center w-[90px] h-[90px] object-cover'>
         <img className='h-[50px] mx-auto' src={`https://www.kcollection.az/kcollection/${item.image}`} alt='' />
       </div>
   <div className="w-[140px]">
     <h1 className="font-titleFont text-[16px] md:text-20px text-white mb-[10px]">{item.name}</h1>
     <p className="font-titleFont text-[16px] md:text-18px text-white mb-[10px]">Ölçü: {item.volume} • Say: {item.quantity} • Gender: {item.gender}</p>
   </div>
   <div>
         <span  onClick={() => dispatch(deleteItem({ id: item.id}))}
     className="text-[#6C6C6C] hover:text-red-500 cursor-pointer flex justify-end  "><i class="fa-solid fa-trash-can"></i></span>
         </div>
 </div>
 <div className="flex items-center gap-6 pr-4 mt-2">
   <div className="flex items-center w-full ml-14 justify-between">
   <p className="text-[#dfb66f] font-semibold text-[16px] md:text-18px">{item.quantity * item.price} AZN</p>
  <div>
  </div>
  
    <div className="md:w-[86px] h-[31px] flex bg-[#6C6C6C] justify-around items-center rounded-[20px]">
         <button className="h-[20px] w-[20px] rounded-[50px] flex justify-center items-center bg-[white] text-[black]"  onClick={() => dispatch(drecreaseQuantity({ id: item.id }))}>-</button>
         <p className="p-2">{item.quantity}</p>
           <button className="h-[20px] w-[20px] rounded-[50px] flex justify-center items-center bg-[black] text-[white]" onClick={() => dispatch(increaseQuantity({ id: item.id}))}>+</button>
         </div>
   </div>

 </div>
</div></>

  );
};

export default ItemCard;
