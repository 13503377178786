import React from 'react'
import DiscountCard from './DiscountCard';
import { discount1, discount2, discount3, hero1, hero2 } from '../../../assets/images';


const Discount = () => {
  return (
     <div className="md:max-w-container max-w-containerSm mx-auto md:flex justify-between py-10 text-[white] ">
      <div className='flex justify-center md:block'><img className='md:h-[391px] h-[345px]' src={hero1}></img></div>
      <div className='md:ml-[42px] mt-[20px] md:mb-0 mb-[20px] md:w-[726px]'>
        <p className='text-[22px] md:text-[40px]'><span className='text-[#DFB66F] text-[24px] md:text-[40px] font-bold'>KCollection</span> Parfümeriyada Zəriflik və Keyfiyyətin Yeni Adı</p>
        <p className='mt-5 md:w-1/2 md:text-[20px] text-[16px] font-light mb-[10px]'>Bizim geniş ətir seçimimizlə sizin üçün mükəmməl ətri kəşf edin</p>
        <a href='/products'><button className='bg-[#DFB66F] text-[black] w-[115px] h-[44px] rounded-[16px]'>İndi al</button></a>
        </div>
      <div className='flex justify-center md:flex-col md:justify-end'><img className='md:h-[298px] h-[361px] w-[288px] md:w-[238px]' src={hero2}></img></div>
  </div>
  )
}

export default Discount 