import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export default function EditProduct() {
  const navigate = useNavigate();
  const { id } = useParams(); // URL'den id parametresini alıyoruz

  const [product, setProduct] = useState({
    brand: '',
    name: '',
    price: '',
    gender: '',
    volume: '',
  });

  const [isLoading, setIsLoading] = useState(true);

  // Sayfa yüklenirken ürünü veritabanından al
  useEffect(() => {
    getProductById();
  }, [id]);

  const getProductById = async () => {
    try {
      const response = await axios.get(
        `https://www.kcollection.az/kcollection/dashboard.php?id=${id}`
      );
      const fetchedProduct = response.data.data[0]; // İlk ürünü alıyoruz
      setProduct(fetchedProduct);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  // Input değişikliklerini yönet
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };


  // Formu gönderme işlemi
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const productData = {
      id,
      brand: product.brand,
      name: product.name,
      price: product.price,
      gender: product.gender,
      volume: product.volume,
    };
  
    try {
      const response = await axios.put(
        `https://www.kcollection.az/kcollection/dashboard.php`,
        productData,  // JSON olarak veri gönderiyoruz
        {
          headers: {
            "Content-Type": "application/json", // JSON veri tipini belirtiyoruz
          },
        }
      );
  
      if (response.data.status === 1) {
        alert("Product updated successfully!");
        navigate(`/dashboard/${id}/edit`);
      } else {
        alert("Error updating product");
      }
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Error updating product");
    }
  };
  
  

  return (
    <div className="max-w-containerSm md:max-w-container mx-auto">
      <h1>Edit Product</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <table className="bg-[white]" cellSpacing="10">
            <tbody>
              <tr>
                <th><label>Brand</label></th>
                <td>
                  <input
                    type="text"
                    name="brand"
                    value={product.brand}
                    onChange={handleChange}
                    required
                  />
                </td>
              </tr>
              <tr>
                <th><label>Name</label></th>
                <td>
                  <input
                    type="text"
                    name="name"
                    value={product.name}
                    onChange={handleChange}
                    required
                  />
                </td>
              </tr>
              <tr>
                <th><label>Price</label></th>
                <td>
                  <input
                    type="number"
                    name="price"
                    value={product.price}
                    onChange={handleChange}
                    required
                  />
                </td>
              </tr>
              <tr>
                <th><label>Gender</label></th>
                <td>
                  <select
                    name="gender"
                    value={product.gender}
                    onChange={handleChange}
                    required
                  >
                    <option value="men">Kişi</option>
                    <option value="women">Qadın</option>
                    <option value="unisex">Unisex</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th><label>Volume</label></th>
                <td>
                  <input
                    type="text"
                    name="volume"
                    value={product.volume}
                    onChange={handleChange}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2" align="right">
                  <button type="submit">Save</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      )}
    </div>
  );
}
