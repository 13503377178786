import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {logo } from "../../assets/images/index";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";

export default function Dashboard() {

  const [isLoading, setIsLoading] = useState(true)

  const allBrands = [
    "ALEXANDRE J", "Azzaro", "BARROIS", "BDK", "BOND No.9",
    "Bvlqari", "C.Hererra", "CHLOE", "CHRISTIAN DIOR", "CLIVE CHRISTIAN",
    "CREED", "DKNY", "DOLCE&GABBANA", "ELIE SAAB", "ESSENTIAL PARFUMS",
    "ESTEE LAUDER", "G.ARMANI", "GIVENCHY", "GUCCI", "GUERLAIN",

    "HERMES", "HUGO BOSS", "INITIO", "JO MALONE", "KILIAN",
    "LALIQUE", "Lancome", "MANCERA", "MARLY", "MEMO",
    "MONTALE", "Moschino", "N.Rodriguez", "ORLOV", "PRADA",
    "ROJA", "SHAIK", "SISLEY", "TIZIANA TERENZI", "TOM FORD",

    "TRUSSARDI", "V.SECRET", "VAN CLEEF", "Versace", "XERJOFF",
    "YSL", "Creed", "Tiziana Terenzi", "Giorgio Armani", "Parfums De Marly",
    "Chopard", "Amouage", "Molecule 04", "Molecule 05", "Xerjoff",
    "Tom Ford", "Mancera", "Bvlgari", "Eilish", "Karloff",

    "Kilian", "Initio", "Hibiscus MahaTá", "Attar", "Rituals of Anfar",
    "Loewe", "Hermes", "Gucci"
  ];
  
  const categories = allBrands.map(brand => ({
    name: brand,
    img: 'logo',  // Buraya markanın logosunun görsel yolunu ekleyin
    path: `/category/${brand}`  // Markadaki boşlukları '-' ile değiştirip küçük harfe dönüştürerek dinamik path oluşturuluyor
  }));
  
  console.log(categories);
  
  const naviget = useNavigate();
  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    naviget("/");
  }
  const user = localStorage.getItem("user");

  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.kcollection.az/kcollection/dashboard.php`,
        { withCredentials: false }
      );
  

      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <>
    <DashboardHeader/>
    <div className="max-w-containerSm md:max-w-container mx-auto py-20">
   <a href="/dashboard/create"> <button  
            type="submit"
             className=" bg-[#DFB66F] text-black px-6 sm:px-7 md:px-8 py-2 rounded-full mb-10">Yeni məhsul yarat</button></a>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
    {categories.map((category, index) => (
      <NavLink
      key={category.name}
      to={`/dashboard${category.path}`}
    >
      <div key={index} className="text-center min-h-20 min-w-20 bg-[white] flex justify-center items-center">
    <p className="mt-2 text-[black]">{category.name}</p>
      </div>
      </NavLink>
    ))}
  </div>
  </div>
    </>
  );
}