import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { resetCart } from "../../Components/redux/orebiSlice";
import { emptyCart } from "../../assets/images/index";
import ItemCard from "./ItemCard";
import Modal from "./Modal";
import { toast } from "react-toastify";
import axios from "axios";

const Basket = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const products = useSelector((state) => state.orebiReducer.products);
  const [totalAmt, setTotalAmt] = useState("");
  const [shippingCharge, setShippingCharge] = useState("");
  const [selectedItems, setSelectedItems] = useState(
    products.reduce((acc, product) => {
      const key = `${product.id}`;
      acc[key] = true; // All items selected by default
      return acc;
    }, {})
  );


  useEffect(() => {
    console.log(selectedItems)
  }, [])
  

  useEffect(() => {
    let price = 0;
    products.forEach((item) => {
      const key = `${item.id}`;
      if (selectedItems[key]) {
        price += item.price * item.quantity;
      }
    });
    setTotalAmt(price);
  }, [products, selectedItems]);

  useEffect(() => {
    if (totalAmt <= 200) {
      setShippingCharge(0);
    } else if (totalAmt <= 400) {
      setShippingCharge(0);
    } else if (totalAmt > 401) {
      setShippingCharge(0);
    }
  }, [totalAmt]);

  const handleSelectItem = (id) => {
    const key = `${id}`;
    setSelectedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };


  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");


  const selectedProducts = products.filter((item) => {
    const key = `${item.id}`;
    return selectedItems[key];
  });
  console.log(selectedProducts)

  const productString = selectedProducts
  .map(
    ({ name, gender, price, quantity, volume }) =>
      `Name: ${name}, Gender: ${gender}, Price: ${price} AZN, Quantity: ${quantity}, Volume: ${volume}`
  )
  .join("; ") + `; Toplam: ${totalAmt} AZN`;

  
  const handleSubmitOrder = async () => {
  

    let inputs = {name:  clientName, email: clientEmail, phone: clientPhone, address: clientAddress, product: productString}
    try {
      // Seçilen ürünleri post isteği ile gönder
      axios.post("https://www.kcollection.az/kcollection/orders.php/", inputs)
      .then(function (response) { 
        navigate("/");  
        setclientName('')
        setclientEmail('')
        setclientPhone('')
        setclientAddress('')
        dispatch(resetCart()); // Sepeti sıfırla

        // console.log(inputs);
      });
      // Başarılı olduğunda yapılacak işlemler
      toast.success("Sifarişiniz qeydə alındı");
      setIsModalOpen(false);
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
    }
  };

  return (
    <>
    {console.log(products)}
    <div className="max-w-containerSm md:max-w-container mx-auto px-4 pb-[40px] md:pb-[150px]">
    <p className="md:pt-10 pt-4 md:pb-7 pb-5 text-[#969696]"><a href="/">Ana səhifə</a> &gt; <span className="text-[white] font-medium">Səbət</span></p>
      {products.length > 0 ? (
        <div>
          <p className="text-[22px] md:text-2xl text-white">{products.length} məhsul</p>
          <div className=" md:flex justify-between]">
            <div className="md:w-2/3 md:mr-10">
            <div className="mt-5">
            {products.map((item) => (
              <div key={`${item.id}`}>
                <ItemCard
                  item={item}
                  isSelected={selectedItems[`${item.id}`]}
                  onSelect={() => handleSelectItem(item.id)}
                />
              </div>
            ))}
          </div>
            </div>
            <div className="md:w-1/3 mt-5">
            <div className="bg-[#3F3F3F] rounded-[20px] p-6 h-[244px] md:w-[383px]">
    <div className="flex flex-col gap-4">
      <h1 className="text-xl font-semibold text-white">Sifariş</h1>
      <div>
        <p className="flex items-center justify-between text-lg px-4 font-medium text-[#BABABA]">
          Məbləğ
          <span className="font-semibold tracking-wide font-titleFont text-white">
            {totalAmt}₼
          </span>
        </p>
        <p className="flex items-center justify-between text-lg px-4 font-medium text-[#BABABA]">
          Çatdırılma
          <span className="font-semibold tracking-wide font-titleFont text-white">
            {shippingCharge}₼
          </span>
        </p>
        <div className="border-t border-gray-200 my-2"></div>
        <p className="flex items-center justify-between text-lg px-4 font-medium text-white">
          Cəmi
          <span className="font-bold tracking-wide text-lg font-titleFont text-white">
            {totalAmt + shippingCharge}₼
          </span>
        </p>
      </div>
      <div className="flex justify-center">
        <button 
          onClick={() => setIsModalOpen(true)} 
          className="w-full h-[44px] bg-[#DFB66F] text-black duration-300 rounded-[16px]">
          Səbəti təsdiqlə
        </button>
      </div>
    </div>
</div>

            </div>
          </div>

        </div>
      ) : ( 
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
        >
          <div>
            <img
              className="w-80 rounded-lg p-4 mx-auto"
              src={emptyCart}
              alt="emptyCart"
            />
          </div>
          <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
            <h1 className="font-titleFont text-xl font-bold">
              SƏBƏTİNİZ BOŞDUR
            </h1>
            <Link to="/products">
              <button className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                Məhsullara keçid et
              </button>
            </Link>
          </div>
        </motion.div>
      )}
    </div>
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="flex justify-between items-center mb-4  ">
            <h2 className="text-lg font-medium text-[white] ">Sizin məlumatlarınız</h2>
            <button onClick={() => setIsModalOpen(false)} className="text-[white]">
              &#10005;
            </button>
          </div>
          <form onSubmit={handleSubmitOrder}>
            <div className="mb-4">
              <label className="block text-[white]">Ad, soyad*</label>
              <input type="text" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#3F3F3F] text-white" 
               placeholder="Adınızı daxil edin"
               onChange={(event) => setclientName(event.target.value)}
               value={clientName}
               required
                />
               
            </div>
            <div className="mb-4">
              <label className="block text-[white]">E-mail*</label>
              <input type="email" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#3F3F3F] text-white"  
              placeholder="Emailinizi daxil edin"
              onChange={(event) => setclientEmail(event.target.value)}
              value={clientEmail}
              required /> 
            </div>
            <div className="mb-4">
              <label className="block text-[white]">Əlaqə nömrəsi*</label>
              <input type="number" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#3F3F3F] text-white" 
              placeholder="Telefon nömrənizi daxil edin"
              onChange={(event) => setclientPhone(event.target.value)}
              value={clientPhone}
              required /> 
            </div>
            <div className="mb-4">
              <label className="block text-[white]">Ünvan*</label>
              <input type="text" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#3F3F3F] text-white"  
              placeholder="Ünvanınızı daxil edin"
              onChange={(event) => setclientAddress(event.target.value)}
              value={clientAddress}
              required /> 
            </div>
            <div className="flex justify-center">
              <button  type="submit" className="h-[40px] pl-3 bg-[#DFB66F] text-black rounded-[12px] w-full">Səbəti təsdiqlə</button>
            </div>
          </form>
      </Modal>  


    </>
  );
};

export default Basket;
