import React, { useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import { product1, product2, product3, product4 } from '../../assets/images';
import axios from 'axios';

const Others = () => {
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.kcollection.az/kcollection/dashboard.php`,
        { withCredentials: false }
      );
  
      // Tüm veriyi al ve sadece ilk 8 tanesini seç
      const limitedData = response.data.data.slice(0, 4);
  
      console.log(limitedData);
      setItems(limitedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  

  if (isLoading) {
    return <p className="text-center text-[white]">Loading...</p>;
  }

  return (
      <div className="max-w-containerSm md:max-w-container mx-auto pb-[36px] md:pb-[160px]">
        <p className="text-center text-[22px] md:text-[36px] text-[#DFB66F] mb-5 md:mb-10">Bənzər məhsullar</p>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 px-4">
        {list.map((product) => (
          <a key={product.id} href={`/product-detail/${product.id}`}>
            <ProductCard
              name={product.name}
              gender={product.gender}
              volume={product.volume}
              price={product.price} 
              image={product.image} 
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Others;