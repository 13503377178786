import React from 'react'



const ProductCard = ({ index, name, gender, volume, price, image }) => {


  const genderText = gender === 'men' 
    ? 'Kişi' 
    : gender === 'women' 
    ? 'Qadın' 
    : gender === 'unisex' 
    ? 'Unisex' 
    : '';


  return (
        <div className='bg-[#3F3F3F] rounded-[20px] w-[271px] h-[280px] pt-[1px] mx-auto md:mx-0' key={index}>
            <div className='m-3 text-[white]'>
              <div className='bg-[white] rounded-xl h-[167px] mb-3 flex justify-center items-center'>
                <img className='w-[147px] mx-auto' src={`https://www.kcollection.az/kcollection/${image}`} alt='' />
              </div>
              <h3 className='text-left text-[16px] md:text-[18px] font-medium'>{name}</h3>
              <h3 className='text-left text-[14px] md:text-base'>{genderText} • <span>{volume} ml</span></h3>
              <h3 className='text-[#dfb66f] text-left text-[18px] md:text-[20px] font-semibold'>{price} AZN</h3>
            </div>
          </div>
  )
}

export default ProductCard